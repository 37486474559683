import React, { Key } from 'react';

import { Alert } from '@grafana/ui';
import { getBackendSrv } from 'app/core/services/backend_srv';
import { ContextSrv } from 'app/core/services/context_srv';

interface Metadata {
  name: string;
  value: any;
  $$hashKey: Key;
}

interface QueryData {
  PanelName: string;
  ValueColumnName: string;
  Metadata: string;
  Value: string;
  RowNo: number | null;
  ItemId: string;
  UserId: number;
}
export class FrivindCustomModifications {
  contextSrv = new ContextSrv();
  metadata: Metadata[] = [];
  orgId: number;
  panelName: string;
  value: string | number | boolean;
  valueColumnName: string;
  rowNo: number | null = null;

  constructor() {
    this.orgId = this.contextSrv.user.orgId;
    this.panelName = '';
    this.value = '';
    this.valueColumnName = '';
    this.rowNo = null;
  }

  alert() {
    return (
      <Alert severity="info" title="Dashboard not saved">
        Dashboard must be saved before alerts can be added.
      </Alert>
    );
  }

  setPanelName(panelName: string) {
    this.panelName = panelName;
    return this;
  }

  setValueColumnName(valueColumnName: string) {
    this.valueColumnName = valueColumnName;
    return this;
  }

  setValue(value: string | number | boolean) {
    this.value = value;
    return this;
  }

  setMetadata(metadata: Metadata[]) {
    this.metadata = metadata;
    return this;
  }

  setRowNo(rowNo: number | null) {
    this.rowNo = rowNo;
    return this;
  }

  getQueryData(): QueryData {
    return {
      PanelName: this.panelName,
      ValueColumnName: this.valueColumnName,
      Metadata: JSON.stringify(this.metadata),
      Value: this.value.toString(),
      RowNo: this.rowNo,
      ItemId: this.metadata[0].$$hashKey.toString(),
      UserId: this.contextSrv.user.id,
    };
  }

  sendData() {
    getBackendSrv()
      .post(`api/orgs/${this.orgId}/modification`, this.getQueryData())
      .then(() => this.alert())
      .catch((err) => {
        console.warn(err);
      });
  }

  getAllCustomModifications() {
    getBackendSrv()
      .get(`api/orgs/${this.orgId}/modifications`)
      .then((res) => {
        this.alert();
        console.log(res);
      })
      .catch((err) => {
        console.warn(err);
      });
  }
}
