import React, { Key, useState } from 'react';
import { Cell } from 'react-table';

import { TimeRange, DataFrame, SelectableValue } from '@grafana/data';

import { Select } from '../Select/Select';

import { TableStyles } from './styles';
import { GrafanaTableColumn, TableFilterActionCallback } from './types';

export interface Props {
  cell: Cell;
  tableStyles: TableStyles;
  onCellFilterAdded?: TableFilterActionCallback;
  columnIndex: number;
  columnCount: number;
  timeRange?: TimeRange;
  userProps?: object;
  frame: DataFrame;
  clickableField?: {
    clickable: boolean;
    type?: 'string' | 'number' | 'select';
    options?: SelectableValue[];
  };
  onCellChanged?: (
    valueColumnName: string,
    value: string | number | boolean,
    metadata: Array<{ name: string; value: number; $$hashKey: Key }>,
    rowNo: number | null
  ) => void;
}

export const TableCell = ({
  cell,
  tableStyles,
  onCellFilterAdded,
  timeRange,
  userProps,
  frame,
  clickableField,
  onCellChanged,
}: Props) => {
  const cellProps = cell.getCellProps();
  const field = (cell.column as unknown as GrafanaTableColumn).field;

  if (!field?.display) {
    return null;
  }

  if (cellProps.style) {
    cellProps.style.minWidth = cellProps.style.width;
    cellProps.style.justifyContent = (cell.column as any).justifyContent;
  }

  let innerWidth = ((cell.column.width as number) ?? 24) - tableStyles.cellPadding * 2;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isInputVisible, setInputVisible] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [inputValue, setInputValue] = useState(cell.value);

  const handleCellClick = () => {
    if (clickableField && clickableField?.clickable) {
      setInputVisible(true);
    }
  };

  const handleInputChange = (event: any) => {
    if (clickableField?.type === 'select') {
      setInputValue(event.value);
      setInputVisible(false);
      valueChanged(event.value);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleInputKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setInputVisible(false);
      valueChanged(parseFloat(inputValue));
    }
  };

  const handleOnBlur = () => {
    setInputVisible(false);
  };

  const valueChanged = (value: string | number | boolean) => {
    if (onCellChanged) {
      const rowNo = (cellProps.key as string).match(/cell_(\d+)_\d+/);
      const metadata: Array<{ name: string; value: any; $$hashKey: Key }> = [
        {
          name: field.name,
          value: value,
          $$hashKey: cellProps.key,
        },
      ];
      onCellChanged(field.name, value, metadata, rowNo ? +rowNo[1] : null);
    }
  };

  const cellRender = cell.render('Cell', {
    field,
    tableStyles,
    onCellFilterAdded,
    cellProps,
    innerWidth,
    timeRange,
    userProps,
    frame,
  });

  if (cellProps.style) {
    cellProps.style.height = '100%';
  }

  const inputField = (
    <div style={cellProps.style}>
      {clickableField?.type === 'select' ? (
        <Select
          options={clickableField?.options}
          onChange={handleInputChange}
          onCloseMenu={handleOnBlur}
          openMenuOnFocus={true}
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%', backgroundColor: '#393a3e' }}
        />
      ) : (
        <input
          type="number" // Set input type to "number" for numeric input
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          onBlur={handleOnBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%', backgroundColor: '#393a3e' }}
        />
      )}
    </div>
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
    <div onClick={handleCellClick}>{isInputVisible ? inputField : cellRender}</div>
  );
};
